/**
 */
Pmp.modules = Pmp.modules || {}

Pmp.modules.SearchTags = {
  renderPicker: function(title, tags_url, onClickTag, elementToMount, any_tags) {
    const helpers = {
      TagItem: React.createClass({
        render: function() {
          const style = {
            color: '#000',
            backgroundColor: this.props.tagColor,
          }

          const iconStyle = {
            color: 'black',
            paddingRight: '4px',
          }

          return (
            <div
              data-tag_group_id={this.props.groupId}
              data-name={this.props.groupName}
              data-tag={this.props.tagName}
              className="tag-item-container float-left new-style"
              onClick={this.props.onClick}
            >
              <div className="tag-item" style={style}>
                {this.props.is_autotag && (
                  <div className="tag-icon float-left" style={iconStyle}>
                    {String.fromCharCode(0xf023)}
                  </div>
                )}
                <div className="tag-name float-left">
                  {this.props.tagNameDisplay}
                </div>
              </div>
            </div>
          )
        },
      }),

      TaggroupFilter: React.createClass({
        render: function() {
          const matching_tags = _.filter(
            this.props.tags,
            function(tag) {
              return tag
                .toLowerCase()
                .includes(this.props.search_text.toLowerCase())
            },
            this
          )
          const tags = matching_tags.map(function(tag) {
            return (
              <helpers.TagItem
                key={this.props.tag_group.id + tag}
                groupName={this.props.name}
                is_autotag={this.props.tag_group.is_autotag}
                groupId={this.props.tag_group.id}
                tagColor={this.props.color}
                tagName={tag}
                tagNameDisplay={
                  this.props.tag_group.tag_name_displays[tag] || tag
                }
                onClick={this.props.onClickHandler}
              />
            )
          }, this)
          return _.size(tags) ? (
            <div style={{ clear: 'both', padding: '2px 0px 5px 0px' }}>
              <div
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  padding: 5,
                }}
              >
                {this.props.name_display}
              </div>
              {tags}
            </div>
          ) : (
            <span />
          )
        },
      }),

      TagsFilter: React.createClass({
        getInitialState: function() {
          return {
            show_filters: false,
            search_text: '',
            any_checkbox: any_tags
          }
        },

        onClickHandler: function(e) {
          if (this.getDOMNode().contains(e.target)) {
            this.setState({ show_filters: true })
          } else {
            this.setState({ show_filters: false })
          }
        },

        onTagClickHandler: function(e) {
          this.setState({ show_filters: false, search_text: '' })
          const selectedTag = $(e.currentTarget),
              selectedTagId = selectedTag.data('tag_group_id'),
              selectedTagGroupName = selectedTag.data('name'),
              selectedTagText = selectedTag.data('tag'),
              compositeTag = selectedTagId + '##' + selectedTagGroupName + '##' + selectedTagText;
          this.props.onClickHandler(compositeTag, this.state.any_checkbox);
        },

        componentWillMount: function() {
          document.addEventListener('click', this.onClickHandler, false)
        },

        componentWillUnmount: function() {
          document.removeEventListener('click', this.onClickHandler, false)
        },

        onChangeHandler: function(e) {
          const selectedValue = $(e.currentTarget).val()
          this.setState({ search_text: selectedValue })
        },

        onAllTagsClickHandler: function (e) {
          this.setState({any_checkbox: false, show_filters: false});
          this.props.onClickHandler(null, false);
        },

        onAnyTagsClickHandler: function (e) {
          this.setState({any_checkbox: true, show_filters: false});
          this.props.onClickHandler(null, true);
        },

        render: function() {
          const tags_rendered = this.props.selectedFilters.tag_groups.map(
            function(tag_group) {
              return (
                <helpers.TaggroupFilter
                  key={tag_group.id}
                  name={tag_group.name}
                  name_display={tag_group.name_display || tag_group.name}
                  search_text={this.state.search_text}
                  tag_group={tag_group}
                  onClickHandler={this.onTagClickHandler}
                  color={tag_group.color_hex}
                  tags={tag_group.tags}
                />
              )
            },
            this
          )
          const all_tag = elementToMount + '-all';
          const any_tag = elementToMount + '-any';

          return (
            <span>
              <div className="show-filters">
                {title}
                <span className="downer" />
              </div>
              <div style={{ position: 'relative' }}>
                {this.state.show_filters ? (
                  <div
                    style={{
                      position: 'absolute',
                      width: 250,
                      zIndex: 100,
                      padding: 10,
                      background: 'white',
                      border: '1px solid #e1e1e1',
                      top: 40,
                    }}
                    className="filter-dd filter-dropdown"
                  >
                    <input
                      type="text"
                      defaultValue={this.state.search_text}
                      onChange={this.onChangeHandler}
                    />
                    <div style={{clear: 'both', padding: '5px', fontSize: '14px', fontWeight: 600}}>
                      <input type="radio"
                             id={all_tag}
                             checked={this.state.any_checkbox === false}
                             onChange={this.onAllTagsClickHandler} /> All Tags
                    </div>
                    <div style={{clear: 'both', padding: '5px', fontSize: '14px', fontWeight: 600}}>
                      <input type="radio"
                             id={any_tag}
                             checked={this.state.any_checkbox === true}
                             onChange={this.onAnyTagsClickHandler} /> Any Tags
                    </div>
                    {tags_rendered}
                  </div>
                ) : (
                  undefined
                )}
              </div>
            </span>
          )
        },
      }),
    }

    $.get(tags_url, function(response) {
      React.render(
        <helpers.TagsFilter
          selectedFilters={response.payload.content}
          onClickHandler={onClickTag}
        />,
        document.getElementById(elementToMount)
      )
    })
  },
}
